import React from 'react';
import Icon1 from '../source/quality.svg';
import Icon2 from '../source/people.svg';
import Icon3 from '../source/peoples.svg';
const Section4 = () => {
    return ( 
        <section className='section4'>
            <h2>NUESTROS VALORES</h2>
            <div className='section4-container'>
                <div>
                    <img src={Icon1} alt='quality'/>
                    <h3>Calidad</h3>
                    <p>La empresa se compromete a ofrecer productos y servicios de alta calidad a sus clientes.</p>
                </div>
                <div>
                    <img src={Icon2} alt='client'/>
                    <h3>Orientación al cliente</h3>
                    <p>La empresa se enfoca en comprender las necesidades y expectativas de sus clientes, brindando soluciones personalizadas y estableciendo relaciones sólidas a largo plazo.</p>
                </div>
                <div>
                    <img src={Icon3} alt='team'/>
                    <h3>Trabajo en equipo </h3>
                    <p>El trabajo en equipo y la colaboración son valores importantes para asegurar una cadena de suministro eficiente y satisfacer las demandas del mercado.</p>
                </div>
            </div>
        </section>
     );
}
 
export default Section4;