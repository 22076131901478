import React from 'react';
import Img1 from '../source/img1.png';
import Icon from '../source/icon.png';
import Icon2 from '../source/arrow.svg';
import Icon3 from '../source/graphic.svg';

const Section3 = () => {
    return ( 
        <section className='section3'  id='section3'>
            <div className='section3-container'>
                <div className='section3-file1'>
                    <img src={Img1} alt=''/>
                    <div>
                        <h3>LA EMPRESA</h3>
                        <h2>Desde 1961 <br/>en la industria</h2>
                        <p>
                            Tres generaciones de la familia, llevando
                            el negocio, los conocimientos y mejorando
                            la calidad de los procesos y productos. 
                        </p>
                        <a href='https://drive.google.com/file/d/1meoEtykTPF_Vj5N5lfmaXdgj7ZRMXp0N/view' target='_blank'style={{textDecoration:'none',color:'black'}}>
                        <button style={{color:'black'}}>
                            <p style={{color:'black'}}>Descargar catálogo</p>
                            <img src={Icon} alt='Qr'/>
                        </button>
                        </a>
                    </div>
                </div>
                <div className='section3-file2'>
                    <div>
                        <img src={Icon2} alt='Icon2'/>
                        <h2>VISIÓN</h2>
                        <p>
                            Empresa Argentina con más de 64 años de antigüedad dedicada a la fabricación de pernos para pistón atendiendo el mercado de reposición, con un enfoque centrado en la mejora continua, brindando máxima calidad y excelencia en el servicio.
                        </p>
                    </div>
                    <div>
                        <img src={Icon3} alt='Icon3'/>
                        <h2>MISIÓN</h2>
                        <p>
                            Ser reconocidos como el proveedor líder en el mercado de reposición argentino, superando las expectativas de nuestros clientes, diferenciándonos por nuestra excelencia en calidad y servicio al cliente. <br/>
                            Buscamos consolidar nuestra presencia en el mercado nacional y aumentar nuestra participación en el mercado común del sur (Mercosur).                        </p>
                    </div>

                </div>
            </div>
        </section>
     );
}
 
export default Section3;