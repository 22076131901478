import React from 'react';
import Logo from '../source/logo.png'

const Nav = () => {
    return ( 
        <section class="top-nav">
            <div>
                <img src={Logo} alt='logo'/>
            </div>
            <input id="menu-toggle" type="checkbox" />
            <label class='menu-button-container' for="menu-toggle">
            <div class='menu-button'></div>
        </label>
            <ul class="menu">
            <li>Home</li>
            <li><a href='#section3'>La Empresa</a></li>
            <li><a href='#section5'>Productos</a></li>
            <li><a href='#footer' >Contacto</a></li>
            </ul>
        </section>
     );
}
 
export default Nav;