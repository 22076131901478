import React from 'react';

const Section6 = () => {
    return ( 
        <section className='section6'>
            <div className='section6-container'>
                <p>
                    Los datos presentados en este catálogo se han compilado con el mayor cuidado, en base a
                    información actualizada disponible. No obstante, la Empresa no asume responsabilidades
                    por errores eventuales ya que los datos indicados se presentan como simples referencias.
                </p>
                <p>
                    Dejamos expresa constancia que las MARCAS mencionadas en el presente catálogo, 
                    lo son al solo efecto de una referencia de los motores en los que pueden ser usado los productos PESCARA, 
                    reconociendo que las mismas son de uso exclusivo de sus respectivos propietarios.
                </p>
            </div>
        </section>
     );
}
 
export default Section6;