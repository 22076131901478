import React from 'react';
import Icon from '../source/icon.png';
import Img from '../source/img2.png';


const Section5 = () => {
    return ( 
        <section className='section5'  id='section5'>
            <div className='section5-container'>
                <div>
                    <h2>POLÍTICAS DE CALIDAD</h2>
                    <p>
                        Consientes que el futuro de nuestra empresa depende de la total satisfacción de las partes interesadas, la gerencia general establece que la calidad es un objetivo permanente en las actividades que desarrolla la organización en su contexto, que tiene como fin último, lograr la máxima conformidad de las mismas.
                        <br/><br/>Talleres Metalúrgicos PESCARAS.R.L, considera al sistema de gestión de calidad como un objetivo estratégico y un factor clave para la competitividad; por lo cual asume la responsabilidad de impulsar y apoyar las acciones necesarias; a través de la participación del personal, las capacitaciones, la mejora continua, el trabajo en equipo, el respeto mutuo y la fidelización de nuestros clientes y proveedores, proporcionando los recursos necesarios para el logro de los mismos.
                        
                    </p>
                    <a href='https://drive.google.com/file/d/1meoEtykTPF_Vj5N5lfmaXdgj7ZRMXp0N/view' target='_blank' style={{textDecoration:'none',color:'black'}}>
                        <button style={{color:'black'}}>
                            <h3 style={{color:'black'}}>Descargar catálogo</h3>
                            <img src={Icon} alt='Qr'/>
                        </button>

                    </a>
                </div>
                <img src={Img} alt='screw'/>
            </div>
        </section>
     );
}
 
export default Section5;