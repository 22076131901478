import React from 'react';
import Qr from '../source/qr.png';
import Icon from '../source/icon.png';

const Section1 = () => {
    return ( 
        <section className='section1' >
            <div className='section1-container'>
                <div>
                    <h1>Pernos de <br/> pistón.</h1>
                    <h3>Calidad de más de <br/>40 años de trayectoria.</h3>
                </div>
                <div>
                    <img src={Qr} alt='Qr' className='section1-qr'/>
                    <a href='https://drive.google.com/file/d/1meoEtykTPF_Vj5N5lfmaXdgj7ZRMXp0N/view' target='_blank' style={{textDecoration:'none',color:'black'}}>
                        <button style={{color:'black'}}>
                            <p style={{color:'black'}}>Descargar catálogo</p>
                            <img src={Icon} alt='Qr'/>

                        </button>

                    </a>
                </div>
            </div>

        </section>
     );
}
 
export default Section1;