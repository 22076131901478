import React from 'react';
import Logo from '../source/logo3.png';
import Qr from '../source/qr.png';
import Icon1 from '../source/location.svg';
import Icon2 from '../source/phone.svg';
import Icon3 from '../source/mail.svg';

const Footer = () => {
    return ( 
        <footer className='footer'  id='footer'>
            <div className='footer-container'>
                <img src={Logo} alt='logo' className='footer-logo'/>
                <div className='footer-section2'>
                    <img src={Qr} alt='logo'/>
                    <a href='https://drive.google.com/file/d/1meoEtykTPF_Vj5N5lfmaXdgj7ZRMXp0N/view' target='_blank' style={{textDecoration:'none',color:'black'}}>
                    <button style={{color:'black'}}>
                        Descargar catálogo
                    </button>
                    </a>

                </div>
                <div className='footer-section3'>
                    <div>
                        <img src={Icon1} alt='logo'/>
                        <p>Av. Sabattini 5039 <br/> Córdoba<br/> Argentina</p>
                    </div>
                    <div>
                        <img src={Icon2} alt='logo' className='footer-phone'/>
                        <p>+54 351 254 7229</p>
                    </div>
                    <div>
                        <img src={Icon3} alt='logo'/>
                        <p>pescara@pernospescara.com</p>
                    </div>
                    <a href='/'>www.pernospescara.com.ar</a>
                   

                </div>
            </div>
        </footer>
     );
}
 
export default Footer;