import React from 'react';
import Img from '../source/img.png';

const Section2 = () => {
    return ( 
        <section className='section2'>
            <div className='section2-container'>
                <img src={Img} alt='certificate'/>
                <div className='section2-separate'></div>
                <p>
                    El proceso productivo implementado en nuestra empresa se ajusta estrictamente a medidas y exigencias de equipos originales, atendiendo a requerimientos de calidad según ISO 9001:2015, con el objetivo de lograr a través de nuestros productos una plena satisfacción del cliente.
                </p>
            </div>

        </section>
     );
}
 
export default Section2;